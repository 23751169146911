import type { IPropertyOrders } from '@/interfaces/IPropertyOrders';
import { perkDefaults, perkFields, perkVisibility } from '@/constants/perks';
import { OptionOfferState } from '@/contexts/OfferContext';

import type { IHrInputData } from '../interfaces/IHrInputData';
import { HrkToEuro } from '../../../../constants/euro';

export const defaults: IHrInputData = {
  flags: {
    mladaOsoba: false,
  },
  values: {
    total: 2000,
    age: 2,
    stopa: 1,
    brojDjece: 0,
    brojUzdrzavanih: 0,
    carLeasingMonthly: 0,
    carLeasingOnce: 0,
    carMaintenance: 0,
    carOther: 0,
    carMonthlyNoVat: 0,
    carMonthlyWithVat: 0,
    carLeasingProvider: 0,
    carLeasingVat: 0,
    carSpecialTax: 0,
    carInsurance: 0,
    carKasko: 0,
    carRegistration: 0,
    carRegistrationVat: 0,
    carLeasingVatOnce: 0,
    invalidnost: 0,
  },
  options: {
    prijevoz: {
      value: 47,
      flag: true,
    },
    nagrada: {
      value: 100,
      flag: true,
    },
    stan: {
      value: 100,
      flag: true,
    },
    podstanar: {
      value: 0,
      flag: false,
    },
    treci: {
      value: 66,
      flag: false,
    },
    ostalo: {
      value: 0,
      flag: false,
    },
    obustave: {
      value: 42.5,
      flag: false,
    },
    ...perkDefaults,
  },
};

export const otherDefaults = {
  ...defaults,
  options: {
    ...defaults.options,
    prijevoz: {
      value: 360 / HrkToEuro,
      flag: true,
    },
    nagrada: {
      value: 93,
      flag: true,
    },
    stan: {
      value: 100,
      flag: true,
    },
  },
};

export const propertyOrderTypes = {
  flags: ['mladaOsoba'],
  values: [
    'total',
    'stopa',
    'brojDjece',
    'brojUzdrzavanih',
    'age',
    'carLeasingMonthly',
    'carLeasingOnce',
    'carMaintenance',
    'carOther',
    'carMonthlyNoVat',
    'carMonthlyWithVat',
    'carLeasingProvider',
    'carLeasingVat',
    'carSpecialTax',
    'carInsurance',
    'carKasko',
    'carRegistration',
    'carRegistrationVat',
    'carLeasingVatOnce',
    'invalidnost',
  ],
  options: ['prijevoz', 'treci', 'nagrada', 'stan', 'podstanar', 'ostalo', 'obustave', ...perkFields],
} as const;

export const offerFields = [
  'total',
  'stopa',
  'brojDjece',
  'brojUzdrzavanih',
  'age',
  'mladaOsoba',
  'prijevoz',
  'treci',
  'nagrada',
  'stan',
  'podstanar',
  'ostalo',
  'obustave',
  ...perkFields,
] as const;

export const offerFieldDefaults = {
  total: OptionOfferState.ReadOnly,
  stopa: OptionOfferState.Editable,
  brojDjece: OptionOfferState.Editable,
  brojUzdrzavanih: OptionOfferState.Editable,
  age: OptionOfferState.ReadOnly,
  mladaOsoba: OptionOfferState.Hidden,
  prijevoz: OptionOfferState.Editable,
  treci: OptionOfferState.Editable,
  nagrada: OptionOfferState.Editable,
  stan: OptionOfferState.Editable,
  podstanar: OptionOfferState.Editable,
  ostalo: OptionOfferState.Editable,
  obustave: OptionOfferState.Editable,
  ...perkVisibility,
} as const;

export const propertyOrders = propertyOrderTypes as unknown as IPropertyOrders;
